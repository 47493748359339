import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const enLan = require("../../../Components/Localization/Languages/English.json");
// const deLan = require('../json/languages/de.json');
const arLan = require("../../../Components/Localization/Languages/Arabic.json");
// const tlLan = require('../json/languages/tl.json');
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
     resources: {
      English: {
        translations: enLan
      },
      // de: {
      //   translations: deLan
      // },
      Arabic: {
        translations: arLan
      },
    //   tl: {
    //     translations: tlLan
    //   },
    },
    fallbackLng: "English",
    debug: true,

     ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,  
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
