import React, { createContext, useState, useEffect, useContext } from 'react';
const ThemeContext = createContext();
export const Context = () => useContext(ThemeContext);
export const ContextProvider = ({ children }) => {

    // const [theme, setTheme] = useState(() => {
    //     const storedTheme = sessionStorage.getItem('theme');
    //     return storedTheme ? storedTheme : 'themeColor';
    // });

    const [language, setLanguage] = useState(() => {
        const storedLanguage = sessionStorage.getItem('language');
        return storedLanguage ? storedLanguage : 'English';
    });

    // useEffect(() => {
    //     document.body.className = `theme-${theme}`;
    //     sessionStorage.setItem('theme', theme);
    // }, [theme]);

    useEffect(() => { sessionStorage.setItem('language', language) }, [language]);

    const toggleLanguage = (lng) => setLanguage(lng)

    return (
        <ThemeContext.Provider value={{ toggleLanguage, language }}>
            {children}
        </ThemeContext.Provider>
    );
};