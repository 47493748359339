import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import Store from "./Store";
import { ContextProvider } from './Context/ContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Provider>
);

serviceWorkerRegistration.unregister();


reportWebVitals();
